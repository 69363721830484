import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { FooterComponent } from "./components/footer/footer.component";
// import { HeaderComponent } from './components/header/header.component';
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { SigninModalComponent } from "./modals/signin-modal/signin-modal.component";
import { SignupModalComponent } from "./modals/signup-modal/signup-modal.component";

import { UserService } from "./services/user.service";
import { MaterialModule } from "../material/material.module";
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderComponent } from "./components/loader/loader.component";
import { ApiService } from "./services/api.service";
import { JwtService } from "./services/jwt.service";
import { GlobalService } from "./services/global.service";
import { AlertBoxModalComponent } from "./modals/alert-box-modal/alert-box-modal.component";
import { RouterModule } from "@angular/router";
import { CoreRoutingModule } from "./core-routing.module";
import { RoomsComponent } from "./components/rooms/rooms.component";

//import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
/*import { TranslateHttpLoader } from '@ngx-translate/http-loader';*/
import { CheckinModal2Component } from "./modals/checkin-modal2/checkin-modal2.component";
import { HotelAuthGuard } from "./guards/hotel-auth.guard";
import { ForgetPasswordComponent } from "./components/forget-password/forget-password.component";
import { ForgetPasswordService } from "./services/forget-password.service";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ResetAuthCheckService } from "./services/reset-auth-check.service";
import { ResetAuthCheckComponent } from "./components/reset-auth-check/reset-auth-check.component";
import { ResetPasswordService } from "./services/reset-password.service";
import { LanguageTranslationComponent } from "./components/language-translation/language-translation.component";
import { LanguageDirective } from "./directives/language.directive";
import { LanguageSaveComponent } from "./components/language-save/language-save.component";
import { Globals } from "./globals";
import { WINDOW_PROVIDERS } from "./window.provider";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { MasterService } from "./services/master.service";
//import { TokenInterceptor } from './interceptors/token.interceptor';
import { AddNewImageModalComponent } from "./modals/add-new-image-modal/add-new-image-modal.component";
import { ManageGalleryService } from "./services/manage-gallery.service";
import { HotelGalleryComponent } from "./components/hotel-gallery/hotel-gallery.component";
import { HeadingTitlesComponent } from "./components/heading-titles/heading-titles.component";
import { HotelBannerComponent } from "./components/hotel-banner/hotel-banner.component";
import { AboutHotelComponent } from "./components/about-hotel/about-hotel.component";
import { SliderShowModalComponent } from "./modals/slider-show-modal/slider-show-modal.component";
import { ConfirmDialogModalComponent } from "./modals/confirm-dialog-modal/confirm-dialog-modal.component";
import { FileValidator } from "./directives/file-input.validator";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { LanguageLoader } from "./classes/CustomLoader";
import { AddNewRoomTypeModalComponent } from "./modals/add-new-room-type-modal/add-new-room-type-modal.component";
import { AddNewAmenitiesComponent } from "./modals/add-new-amenities/add-new-amenities.component";
import { HeaderComponent } from "./components/header/header.component";
import { SiteLogoDirective } from "./directives/site-logo.directive";
import { AddNewHotelGuideComponent } from "./modals/add-new-hotel-guide/add-new-hotel-guide.component";
import { HotelRoomsComponent } from "./components/hotel-rooms/hotel-rooms.component";
import { HotelRestaurantsComponent } from "./components/hotel-restaurants/hotel-restaurants.component";
import { AddNewResturantTypeModalComponent } from "./modals/add-new-resturant-type-modal/add-new-resturant-type-modal.component";
import { HotelGuideComponent } from "./components/hotel-guide/hotel-guide.component";
import { AboutHotelDetailPageComponent } from "./components/about-hotel-detail-page/about-hotel-detail-page.component";
import { UpdateRoomTypeModalComponent } from "./modals/update-room-type-modal/update-room-type-modal.component";
import { UpdateResturantModalComponent } from "./modals/update-resturant-modal/update-resturant-modal.component";

// import { SpecialCharacterDirective } from './directives/special-character.directive';
// import { SpecialCharacterControlDirective } from './directives/special-character-control.directive';

/*export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/1234/i18n/', '.json');
}*/

import { AclRedirection } from "./services/acl-redirection";
import { AclDemoResolver } from "./resolver/acl-demo-resolver";
import { ActivatePageComponent } from "./components/activate-page/activate-page.component";
import { AccessCheckDirective } from "./directives/access-check.directive";
import { CanActivateTeamGuard } from "./guards/can-activate-team.guard";
import { PdfUploadModalComponent } from "./modals/pdf-upload-modal/pdf-upload-modal.component";
import { SafePipe } from "./pipes/safe.pipe";

// import { DndModule } from "ng2-dnd";
import { RouteRefreshDirective } from "./directives/route-refresh.directive";
import { AddStaffComponent } from "./modals/add-staff/add-staff.component";
import { AddNewInclusionExclusionComponent } from "./modals/add-new-inclusion-exclusion/add-new-inclusion-exclusion.component";
import { HotelServicesComponent } from "./components/hotel-services/hotel-services.component";
import { HotelToursComponent } from "./components/hotel-tours/hotel-tours.component";
import { GoogleMapComponent } from "./modals/google-map/google-map.component";
import { GoogleMapCommonComponent } from "./components/google-map-common/google-map-common.component";
import { FetchJsonPipe } from "./pipes/fetch-json.pipe";
import { DateFormatPipe } from "./pipes/date-format.pipe";
import { TimeFormatPipe } from "./pipes/time-format.pipe";
import { GuestAuthGuard } from "./guards/guest-auth.guard";
import { PaymentSuccessPageComponent } from './components/payment-success-page/payment-success-page.component';
import { PaymentCancelPageComponent } from './components/payment-cancel-page/payment-cancel-page.component';
import { MessageViewOrderComponent } from './modals/message-view-order/message-view-order.component';
import { ItemsReStockModalComponent } from './modals/items-re-stock-modal/items-re-stock-modal.component';
import { ItemsRestockConfirmationComponent } from './modals/items-restock-confirmation/items-restock-confirmation.component';
import { InventoryRequestDeclinePopupComponent } from './modals/inventory-request-decline-popup/inventory-request-decline-popup.component';
import { PendingBillsPaymentSuccessComponent } from './components/pending-bills-payment-success/pending-bills-payment-success.component';
import { AssignedRestaurantStaffComponent } from './modals/assigned-restaurant-staff/assigned-restaurant-staff.component';
import { CountDownTimerComponent } from './components/count-down-timer/count-down-timer.component';
import { GuestOrderListComponent } from './components/guest-order-list/guest-order-list.component';
import { CalenderFilterComponent } from './components/calender-filter/calender-filter.component';
import { PrintRestaurantOrderComponent } from './modals/print-restaurant-order/print-restaurant-order.component';
import { RestroOrderHistoryAdminComponent } from './components/restro-order-history-admin/restro-order-history-admin.component';
import { GoogleMapsModule } from "@angular/google-maps";
import { MessageViewOrderGuestComponent } from './modals/message-view-order-guest/message-view-order-guest.component';


@NgModule({
    declarations: [
        HeaderComponent,
        DashboardComponent,
        FooterComponent,
        PageNotFoundComponent,
        SigninModalComponent,
        SignupModalComponent,
        LoaderComponent,
        AlertBoxModalComponent,
        RoomsComponent,
        CheckinModal2Component,
        ForgetPasswordComponent,
        ResetPasswordComponent,
        ResetAuthCheckComponent,
        LanguageTranslationComponent,
        LanguageDirective,
        LanguageSaveComponent,
        FileUploadComponent,
        AddNewImageModalComponent,
        HotelGalleryComponent,
        HeadingTitlesComponent,
        HotelBannerComponent,
        AboutHotelComponent,
        SliderShowModalComponent,
        ConfirmDialogModalComponent,
        FileValidator,
        AddNewRoomTypeModalComponent,
        AddNewAmenitiesComponent,
        SiteLogoDirective,
        AddNewHotelGuideComponent,
        HotelRoomsComponent,
        HotelRestaurantsComponent,
        AddNewResturantTypeModalComponent,
        HotelGuideComponent,
        AboutHotelDetailPageComponent,
        UpdateRoomTypeModalComponent,
        UpdateResturantModalComponent,
        ActivatePageComponent,
        AccessCheckDirective,
        PdfUploadModalComponent,
        SafePipe,
        RouteRefreshDirective,
        AddStaffComponent,
        AddNewInclusionExclusionComponent,
        HotelServicesComponent,
        HotelToursComponent,
        GoogleMapComponent,
        GoogleMapCommonComponent,
        FetchJsonPipe,
        DateFormatPipe,
        TimeFormatPipe,
        PaymentSuccessPageComponent,
        PaymentCancelPageComponent,
        MessageViewOrderComponent,
        ItemsReStockModalComponent,
        ItemsRestockConfirmationComponent,
        InventoryRequestDeclinePopupComponent,
        PendingBillsPaymentSuccessComponent,
        AssignedRestaurantStaffComponent,
        CountDownTimerComponent,
        GuestOrderListComponent,
        CalenderFilterComponent,
        PrintRestaurantOrderComponent,
        RestroOrderHistoryAdminComponent,
        MessageViewOrderGuestComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        MaterialModule,
        RouterModule,
        CoreRoutingModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: "toast-bottom-right",
            preventDuplicates: true
        }),
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useClass: LanguageLoader
            }
        }),
        //   TranslateModule.forRoot({
        //     loader: {
        //         provide: TranslateLoader,
        //         useFactory: (languageLoader),
        //         deps: [HttpClient]
        //     }
        // })
        // DndModule.forRoot(),
        GoogleMapsModule,

    ],
    exports: [
        LoaderComponent,
        LanguageTranslationComponent,
        LanguageDirective,
        LanguageSaveComponent,
        FileUploadComponent,
        HotelGalleryComponent,
        HeadingTitlesComponent,
        HotelBannerComponent,
        AboutHotelComponent,
        FileValidator,
        SiteLogoDirective,
        HotelRoomsComponent,
        HotelRestaurantsComponent,
        HotelGuideComponent,
        AboutHotelDetailPageComponent,
        AccessCheckDirective,
        SafePipe,
        HotelServicesComponent,
        HotelToursComponent,
        GoogleMapComponent,
        GoogleMapCommonComponent,
        FetchJsonPipe,
        DateFormatPipe,
        TimeFormatPipe,
        CountDownTimerComponent,
        GuestOrderListComponent,
        CalenderFilterComponent,
        RestroOrderHistoryAdminComponent,
    ],
    providers: [
        HotelAuthGuard,
        UserService,
        ApiService,
        JwtService,
        GlobalService,
        ForgetPasswordService,
        ResetAuthCheckService,
        ResetPasswordService,
        Globals,
        WINDOW_PROVIDERS,
        MasterService,
        ManageGalleryService,
        AclRedirection,
        AclDemoResolver,
        CanActivateTeamGuard,
        GuestAuthGuard
    ]
})
export class CoreModule {}
