import { Component } from '@angular/core';

@Component({
  selector: 'app-message-view-order-guest',
  templateUrl: './message-view-order-guest.component.html',
  styleUrls: ['./message-view-order-guest.component.scss']
})
export class MessageViewOrderGuestComponent {

}
